$(document).on('turbolinks:load', function(){
  const $deviceSelect = $('.js-device-select');
  const $replacementTypeField = $('.js-replacement-type-select');

  populateDeviceTypes($deviceSelect, $replacementTypeField); // specific to edit page
  setupDeviceSelect($deviceSelect);
  toggleOriginalDevice2();
  registerOnChangeEventHandlers($deviceSelect, $replacementTypeField);
});

function populateDeviceTypes($deviceSelect, $replacementTypeField) {
  $deviceSelect.each(function(){
    $(this).data('replacementType', $replacementTypeField.val());

    const $deviceTypeField = $(this).closest('.js-device-inputs').find('.js-device-type-select');
    const oldOriginalDeviceTypeValue = $deviceTypeField.val();
    updateOriginalDeviceType($deviceTypeField, () => {
      $deviceTypeField.val(oldOriginalDeviceTypeValue).trigger('change');
    });
  });
}

function setupDeviceSelect($deviceSelect) {
  const ajaxParams = {
    url: '/search/devices',
    dataType: 'json',
    type: 'GET',
    delay: 300,
    data: function(params) {
      return {
        query: params.term
      };
    },
    processResults: function(data) {
      return {
        results: $.map(data, function(device) {
          return {
            id: device.id,
            text: device.device_details,
            deviceCode: device.code,
            deviceTypeId: device.device_type_id,
            bundle: device.bundle,
          };
        })
      };
    },
  };

  $deviceSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 6,
    ajax: ajaxParams,
  }).on('select2:select', function(e) {
    populateDeviceDetails($(this), e.params.data);
  }).on('select2:clear', function() {
    clearDeviceDetails($(this));
  });
}

function registerOnChangeEventHandlers($deviceSelect, $replacementTypeField) {
  $replacementTypeField.on('change', function() {
    $deviceSelect.each(function() {
      $(this).data('replacementType', $replacementTypeField.val());
    });

    $('.js-device-type-select').each(function(index, deviceTypeField) {
      updateOriginalDeviceType($(deviceTypeField), () => {
        $(deviceTypeField).val(""); // clear selection after values are repopulated
      });
    });
    updateProposedDeviceType();

    toggleOriginalDevice2();
  });

  const $originalDeviceTypeFields = $('.js-original-device-type-select');
  $originalDeviceTypeFields.on('change', () => updateProposedDeviceType());

  $('#ticket_original_device_id')
    .on('select2:select', function() {
      toggleOriginalDevice2();
    }).on('select2:clear', function() {
      clearDeviceDetails($(this));
    });
}

function populateDeviceDetails(element, data) {
  if ($.isEmptyObject(data)) {
    return;
  }

  const $formObject = element.closest('.js-device-inputs');

  const $deviceCodeField = $formObject.find('.js-device-code');
  const $deviceTypeField = $formObject.find('.js-device-type-select');
  updateOriginalDeviceType($deviceTypeField, () => {
    $deviceCodeField.val(data['deviceCode']);
    $deviceTypeField.val(data["deviceTypeId"]).trigger('change');
  });
}

function clearDeviceDetails(element) {
  const $formObject = element.closest('.js-device-inputs');

  const $deviceCodeField = $formObject.find('.js-device-code');
  const $deviceTypeField = $formObject.find('.js-device-type-select');

  $deviceCodeField.val("");
  $deviceTypeField.val("").trigger('change');
}

function updateOriginalDeviceType($deviceTypeField, onSuccess = () => {}) {
  if ($deviceTypeField.length === 0) {
    return;
  }

  const $deviceSelectField = $deviceTypeField.closest('.js-device-inputs').find('.js-device-select');
  const replacementType = $deviceSelectField.data('replacementType');

  // eslint-disable-next-line camelcase
  const data =  { replacement_type: replacementType, type: 'original' };
  fetchDeviceTypes(data, $deviceTypeField, onSuccess);
}

function updateProposedDeviceType() {
  const $proposedDeviceTypeField = $('.js-proposed-device-type-select');
  if ($proposedDeviceTypeField.length === 0) {
    return;
  }

  const deviceTypeIds = $('.js-original-device-type-select').toArray().map(e => e.value);
  const replacementType = $('.js-replacement-type-select').val();

  // eslint-disable-next-line camelcase
  const data = { device_type_ids: deviceTypeIds, replacement_type: replacementType, type: 'proposed' };
  const oldProposedDeviceTypeValue = $proposedDeviceTypeField.val();
  fetchDeviceTypes(data, $proposedDeviceTypeField, () => {
    $proposedDeviceTypeField.val(oldProposedDeviceTypeValue).trigger('change');
  });
}

function fetchDeviceTypes(data = {}, $targetDeviceTypeField, onSuccess = () => {}) {
  $.ajax({
    url: '/search/device_types',
    data: data,
    type: 'GET',
    dataType: 'json',
    success: function(data) {
      updateSelectOptions($targetDeviceTypeField, data);
      onSuccess();
    },
    error: function(xhr, status, error) {
      console.error('AJAX request failed');
      console.error(status, error);
    }
  });
}

function updateSelectOptions($select, data) {
  $select.empty();
  data.forEach(deviceType => {
    const option = new Option(deviceType.text, deviceType.id, true, true);
    $select.append(option);
  });
}

function toggleOriginalDevice2() {
  if ($('#ticket_original_device_id').length === 0) {
    return;
  }

  const $replacementTypeField = $('.js-replacement-type-select');
  const originalDeviceSelection = $('#ticket_original_device_id').select2('data')[0];

  // empty selection still returns result with id being empty string
  if (!originalDeviceSelection.id) {
    return;
  }

  // on edit or rerender originalDeviceSelection.bundle is undefined, so data-bundle on original-device-select is used
  const isBundle = originalDeviceSelection.bundle || $('.js-original-device-select').data('bundle');
  if ($replacementTypeField.val() === 'kit_for_kit' && isBundle === true) {
    showOriginalDevice2();
  } else {
    hideOriginalDevice2();
  }
}

function showOriginalDevice2() {
  const $originalDevice2Details = $('#original-device-2-details');
  toggleDisabledOnDeviceDetails($originalDevice2Details, false);

  $originalDevice2Details.removeClass('d-none');
}

function hideOriginalDevice2() {
  const $originalDevice2Details = $('#original-device-2-details');
  const originalDevice2Select = $originalDevice2Details.find('.js-device-select');
  originalDevice2Select.val("");

  clearDeviceDetails(originalDevice2Select);
  toggleDisabledOnDeviceDetails($originalDevice2Details, true);

  $originalDevice2Details.addClass('d-none');
}

function toggleDisabledOnDeviceDetails($container, isDisabled) {
  $container.find('input, select').prop('disabled', isDisabled);
}
